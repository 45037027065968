import {
  Hero,
  AboutLikeSection,
  AffiliateCTA,
  Card,
  WhatWeOffer,
  WhyChooseUs,
} from "../components";
import config from "../utils/config";

// IMPORTING ALL IMAGES START
import about from "../assets/about.png";
import membership from "../assets/membership.png";
import peopleIcon from "../assets/svgs/peopleIcon.svg";
import buynowpaylater from "../assets/svgs/buynowpaylater.svg";
import walletIcon from "../assets/svgs/wallet-icon.svg";
import investmentIcon from "../assets/svgs/investment-icon.svg";
import about1 from "../assets/acessibility.png";
import about2 from "../assets/about-2.png";
import about3 from "../assets/about-3.png";
import about4 from "../assets/about-4.png";
import about5 from "../assets/about-01.jpg";
import about6 from "../assets/about-02.jpg";
import about7 from "../assets/about-03.jpg";
import about8 from "../assets/about-04.jpg";

import accessIcon from "../assets/svgs/accessIcon.svg";
import portfolioIcon from "../assets/svgs/portfolioIcon.svg";
import FAQ from "../components/FAQ";
// IMPORTING ALL IMAGES END

import { homepageFAQ } from "../utils/content";

function Home() {
  return (
    <div className="App">
      {/* Hero Section */}
      <Hero />
      {/* About Us */}
      <AboutLikeSection
        bg={true}
        img={about}
        header="About Us"
        text="SafeHome is more than just another run-of-the-mill financial platform. We are a people-driven
				Multipurpose Cooperative Society. We are a community, not just an institution, and in there lies
				our unique strength."
        btnText="Learn More"
        btnUrl="/about"
        imgSide="right"
      />
      {/* Membership */}
      <AboutLikeSection
        img={membership}
        header="Membership"
        text={
          <span>
            {" "}
            SafeHome is a modern way to save and grow your money. Your journey
            to financial ease is just three steps away:
            <br />
            1. Pay a one-time fee.
            <br />
            2. Join our exclusive community.
            <br />
            3. Access premium real-estate investment opportunities.
          </span>
        }
        btnText="Become a Member"
        btnUrl={config.urlMaps.signUp}
        imgSide="left"
      />
      {/* Affiliate CTA */}
      <AffiliateCTA />
      {/* Our Products */}
      <div className="pt-[35px] pb-[60px] md:py-[100px] container">
        <div className="flex flex-col gap-[15px] max-w-[604px] mx-auto text-center">
          <h1 className="text-[--header]">Our Products</h1>
          <p>
            Discover our range of innovative products designed to enhance your
            lifestyle and unleash your financial potential
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[1.5rem] md:gap-[2rem] pt-[35px] md:pt-[50px]">
          <Card
            icon={walletIcon}
            title="Savings"
            text="Cultivate your financial goals with personalized and group savings plans. Save and withdraw your
			money at any time."
          />
          <Card
            icon={investmentIcon}
            title="Loans"
            text="Low on funds? Enjoy access to credit with competitive interest rates tailored to suit your personal
			or business needs. No collaterals. No long forms."
          />
          <Card
            icon={buynowpaylater}
            title="Buy Now Pay Later"
            text="Why wait to cop your favorite things? Shop now and pay at your pace with the freedom of our Buy
			Now, Pay Later feature!"
          />
        </div>
      </div>
      {/* What we Offer */}
      <WhatWeOffer />
      {/* Why Choose Us */}
      <div className="pt-[35px] pb-[35px] md:py-[100px]">
        <h1 className="text-[--header] text-center">Why Choose Us</h1>
        <div className="pt-[0px] lg:pt-[50px]">
          <WhyChooseUs
            img={about1}
            header="Online Accessibility"
            text="Save, invest, and earn all in one place. Wherever you are, whenever you want, access the full
            range of our cooperative society features from the comfort of your home. Operate and track your
            portfolio seamlessly through our user-friendly website."
            imgSide="right"
          />
          <WhyChooseUs
            img={about8}
            header="Award-winning Expertise"
            text="What happens when global finance and real estate experts come together? Find out on
            SafeHome. Gain access to unparalleled insights and carefully curated investment opportunities
            from our accomplished team."
            imgSide="left"
          />
          <WhyChooseUs
            img={about5}
            header="Automated Savings"
            text="Take the stress out of saving with our automated savings plan designed for your convenience.
            We meet you where you are on your savings journey."
            imgSide="right"
          />
          <WhyChooseUs
            img={about3}
            header="Top-Notch Security"
            text="Your security is our priority. PCI-DSS-compliant payment processors safeguard your funds. All
            personal data is encrypted with state-of-the-art symmetric bank-level encryption."
            imgSide="left"
          />

          <WhyChooseUs
            img={about7}
            header="Unlock lucrative Returns"
            text="Earn investment returns at much higher rates than traditional savings accounts."
            imgSide="right"
          />

          <WhyChooseUs
            img={about4}
            header="Community Focus"
            text="Success is a shared journey. Our community-centered growth approach ensures user-friendly
            tools are readily available for your financial advancement."
            imgSide="left"
          />
          <WhyChooseUs
            img={about6}
            header="24/7 Service"
            text="Our dedicated support team is at your service round-the-clock to answer your questions and help
            you reach those goals faster. Your financial well-being is our utmost concern."
            imgSide="right"
          />
        </div>
      </div>

      {/* Our Products */}
      <div className="pb-[60px] md:pb-[100px] container">
        <div className="flex flex-col gap-[15px] max-w-[604px] mx-auto text-center">
          <h1 className="text-[--header]">How It Works</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[1.3rem] md:gap-[2rem] pt-[35px] md:pt-[50px]">
          <Card
            icon={peopleIcon}
            title="Become a Member"
            text="Register on our website or download our app from the App Store."
          />
          <Card
            icon={accessIcon}
            title="Get Access"
            text="Access premium investment opportunities and savings plans."
          />
          <Card
            icon={portfolioIcon}
            title="Portfolio"
            text="Save, Invest, Relax, and watch your portfolio grow."
          />
        </div>
      </div>

      <FAQ faq={homepageFAQ} />
    </div>
  );
}

export default Home;
