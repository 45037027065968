import config from "../utils/config";
import { useEffect } from "react";

const Membership = () => {
  useEffect(() => {
    window.location.href = config.urlMaps.signUp;
  }, []);
  return (
    <div className="pt-[80px]">
      {/* Spinner for loading page  */}

      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-28 w-28 border-t-2 border-r-2 border-gray-900"></div>
      </div>
    </div>
  );
};

export default Membership;
