import config from "../utils/config";

const AffiliateCTA = () => {
  return (
    <div className="bg-[var(--light-bg)] py-[35px] md:py-[70px]">
      <div className="container">
        <div className="max-w-[599px] mx-auto flex flex-col items-center text-center gap-[20px]">
          <h1 className="text-[var(--header)]">Become A SafePartner</h1>
          <h3 className="text-[--text]">
            With SafeHome, Sharing is Rewarding. Invite your friends and earn up
            to 40% per membership referral.
          </h3>
          <a
            href={config.urlMaps.affiliate}
            className="bg-[var(--btn)] text-white w-[256px] py-3 rounded-[2rem] btn"
          >
            Become A Partner
          </a>
        </div>
      </div>
    </div>
  );
};

export default AffiliateCTA;
