import React from "react";

// import { Card, VisionMissionCard, TrackRecord, JoinUsCTA } from "../components";
import { TrackRecord, Card, JoinUsCTA } from "../components";

// IMPORTING ALL IMAGES START
import goalbased from "../assets/goal-based.png";
import lockedsavings from "../assets/locked-savings.png";
import buynow from "../assets/BuyNow.png";

// import switchIcon from "../assets/svgs/switchIcon.svg";
// import documentsIcon from "../assets/svgs/documentsIcon.svg";
// import marketplaceIcon from "../assets/svgs/marketplaceIcon.svg";
// import officeIcon from "../assets/svgs/officeIcon.svg";
import personalLoans from "../assets/personal-loans.png";
import FAQ from "../components/FAQ";
import { homepageFAQ } from "../utils/content";

const Services = () => {
	return (
		<div className="pt-[80px]">
			<div
				id="savings"
				className="bg-[--light-highlight2] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]"
			>
				<div className="container">
					<div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
						<h1 className="text-[--header]">Savings</h1>
						<p className="font-semibold">
							At SafeHome Cooperative, there are two types of savings plans to
							suit every shade of you.
						</p>
					</div>
				</div>
			</div>

			<div className="pt-[25px] md:pt-[50px]">
				<TrackRecord
					img={goalbased}
					header="Goal-Based Savings"
					text={
						<div className="space-y-4">
							<p>
								With our goal-based savings plan, you can set specific financial
								targets and save towards achieving them. Whether it's a dream
								vacation, buying a car, or building an emergency fund, this plan
								allows you to turn your financial dreams into tangible
								objectives.
							</p>

							<p>
								You can create multiple savings goals, name them, and set the
								target savings amount. We provide the tools to track your
								progress and celebrate each milestone you achieve. If you
								withdraw your funds prematurely before reaching your goal, you
								will forfeit all accrued interests and incur a small fee. Who
								says saving money isn’t fun!
							</p>
						</div>
					}
					imgSide="right"
				/>
				<TrackRecord
					img={lockedsavings}
					header="Investment Savings"
					text="What if you want significantly higher returns on your savings? Well, we’ve got the perfect solution
		  for you! SafeHome’s Investment savings feature allows you to lock in your savings for a set
		  duration, exclusively to invest in lucrative real estate opportunities. With this time-based
		  investment plan, you know exactly how much you will be earning when your investment matures.
		  This makes it a smart and flexible way to hedge your savings against inflation, earn juicy interest
		  rates, and earn dividend while doing less work."
					imgSide="left"
				/>
			</div>

			<div
				id="loans"
				className="bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]"
			>
				<div className="container">
					<div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
						<h1 className="text-[--header]">Loans </h1>
						<p className="font-semibold">
							With SafeHome, you can grow your business or fix personal
							emergencies with affordable and collateral-free loans, or unlock
							instant gratification and flexible payments with Our Buy Now, Pay
							Later Revolution.
						</p>
					</div>
				</div>
			</div>

			<div className="pt-[25px] md:pt-[50px]">
				<TrackRecord
					img={personalLoans}
					header="Personal loans"
					text="At SafeHome, we understand that life can present unforeseen challenges or exciting
		  opportunities that require financial support. That is why we offer customizable personal
		  loans tailored to fit your unique circumstances. The more you save, the more you can
		  borrow. With competitive interest rates and flexible repayment terms, achieving your
		  goals has never been this easy."
					imgSide="right"
				/>

				<TrackRecord
					img={buynow}
					header="Buy Now Pay Later"
					text={`Sometimes you may want to indulge in life's little pleasures without the immediate
		  financial burden, and that’s okay. SafeHome’s exclusive "Buy Now, Pay Later" loan option
		  is a flexible and convenient way to shop for the things you love, and pay for them on your
		  terms, at your pace. Embrace the freedom to chop life without compromising your
		  financial stability.`}
					imgSide="left"
				/>
			</div>

			<div className="hidden bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]">
				<div className="container">
					<div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
						<h1 className="text-[--header] flex gap-4 items-center">
							Loans{" "}
							<span className="text-xl md:text-3xl text-gray-400 inline-block">
								(Coming Soon)
							</span>
						</h1>
						<p>
							Stay tuned for future updates! Soon, we will introduce a loan
							system to our members, offering additional financial opportunities
							and services to further enrich your cooperative experience.
						</p>
					</div>
				</div>
			</div>

			{/* CTA */}
			<JoinUsCTA />

			<div className="container py-[35px] md:py-[80px]">
				<h3 className="multi-color-text text-center md:text-[2rem] md:leading-[3.3rem] xl:text-[2.5rem] xl:leading-[3.8rem] max-w-[850px] mx-auto">
					At SafeHome Cooperative, <span>your growth is our priority. </span>
					Our personalized savings and <span>
						{" "}
						investment plans simplify{" "}
					</span>{" "}
					your financial journey <span> and empower you to achieve </span> your
					goals with financial discipline.{" "}
					<span>Join our fast-growing community today </span>and watch your
					portfolio take a massive leap.
				</h3>
			</div>

			<FAQ faq={homepageFAQ} />
		</div>
	);
};

export default Services;
