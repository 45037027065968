import { Hero2 } from "../components";
import callerSuit from "../assets/caller-suit.png";
import ladyPhone from "../assets/lady-phone.jpg";
import { TrackRecord } from "../components";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import {
	FaFacebook,
	FaTwitter,
	FaLinkedin,
	FaWhatsapp,
	FaInstagram,
} from "react-icons/fa";
import ContactUsForm from "../components/ContactUsForm";
import FAQ from "../components/FAQ";
import { homepageFAQ } from "../utils/content";

const Contact = () => {
	return (
		//  Contact us page  with contact us form

		<section>
			<div className="pt-[80px]">
				<Hero2
					img={ladyPhone}
					header="Contact Us"
					// header2=""
					text="We're here to assist you. Feel free to reach out to our dedicated support team with any questions, concerns, or feedback."
					imgSide="right"
				/>

				<div className="">
					<TrackRecord
						img={callerSuit}
						header="Our Business Time"
						text={[
							"We aim to respond to your inquiries promptly. Our support team are available to respond to your inquiries instantly during these hours:",
							<br />,
							"Monday to Friday",
							<br />,
							"9:00AM - 5:00PM GMT +1",
							<br />,
							<br />,
							"Your satisfaction is our priority!",
						]}
						imgSide="left"
						bg="yes"
					/>

					{/* <TrackRecord
						header="Get in touch with us"
						text="At SafeHome, we believe in the power of connection, 
          and we're committed to building strong relationships
          with our customers."
						imgSide="right"
					/> */}
					<div className="container pt-[50px] pb-[35px] lg:pt-[100px] md:pb-[50px]">
						<div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
							<h2 className="text-[--header] text-xl md:text-3xl lg:text-5xl capitalize">
								Get in touch with us
							</h2>
							<p>
								At SafeHome, we believe in the power of connection, and we're
								committed to building strong relationships with our customers.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className={`w-full pt-[0px] pb-[60px] lg:pb-[100px]`}>
				<div className="container">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-[0px] justify-center items-center max-w-[1000px] mx-auto rounded-r-[8px]">
						<ContactUsForm />

						{/* RIght Card starts  */}

						<div className="bg-[#1E0700] order-0 md:order-1 py-12 md:py-16 px-5 md:px-8 lg:px-12 rounded-l-[8px] md:rounded-l-non rounded-r-[8px] space-y-12 md:space-y-16 self-stretch">
							<h2 className="text-white font-bold text-xl md:text-2xl lg:text-2xl ">
								{" "}
								Contact Information{" "}
							</h2>

							<ul className="space-y-10 text-white ">
								<div className="w-full flex flex-row justify-start gap-4 items-center">
									{" "}
									<div className="block w-[40px]">
										<IoLocationOutline className="text-3xl w-[50px]" />
									</div>
									<span className="">
										Lapal House, 235 Igbosere Road, Onikan, Lagos
									</span>
								</div>

								<a
									href="tel:0812 994 0741"
									className="w-full flex flex-row justify-start gap-4 items-center"
								>
									{" "}
									<div className="block w-[40px]">
										<BsTelephone className="text-2xl w-[50px]" />
									</div>
									<div className="flex flex-col justify-start items-center">
										<span className=""> +234 812 994 0741 </span>

										<span className=""> +234 812 994 0742 </span>
									</div>
								</a>

								<div className="w-full flex flex-row justify-start gap-4 items-center">
									{" "}
									<div className="block w-[40px]">
										<BsEnvelope className="text-2xl w-[50px]" />
									</div>
									<span className=""> support@safehome.com </span>
								</div>
							</ul>

							<div className="space-y-4">
								<p className="text-white">
									{" "}
									Follow us on social media for updates, news, and more:
								</p>

								<div className="flex flex-row justify-start space-x-4 md:space-x-8 items-center text-white text-2xl pt-1">
									<a href="https://www.instagram.com/safehomecoop/">
										<FaInstagram className="text-3xl" />
									</a>

									<a href="https://web.facebook.com/safehomecoop/">
										<FaFacebook className="text-3xl" />
									</a>

									<a href="https://twitter.com/safehomecoop">
										<FaTwitter className="text-3xl" />
									</a>

									<a href="https://www.linkedin.com/in/safe-home/">
										<FaLinkedin className="text-3xl" />
									</a>

									<a href="https://wa.me/2348129940741">
										<FaWhatsapp className="text-3xl" />
									</a>
								</div>
							</div>
						</div>

						{/* right card ends  */}
					</div>
				</div>
			</div>

			<FAQ faq={homepageFAQ} />
		</section>
	);
};

export default Contact;
