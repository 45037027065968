export const homepageFAQ = [
  {
    question: "What is Safehome?",
    answer:
      "SafeHome is more than just another run-of-the-mill financial platform. We are a people-driven multipurpose cooperative society. We are a community, not just an institution, and in there lies our unique strength. We are building a platform that empowers young professionals and entrepreneurs to scale their finances by providing access to well-curated savings and investment opportunities.",
  },
  {
    question: "How do I use Safehome?",
    answer:
      "To use SafeHome, you can sign up for membership and explore our platform to access various financial services such as investment opportunities, savings plans, and loans. Our user-friendly interface makes it easy to navigate and utilize our services to grow your finances.",
  },
  {
    question: "Why do I need to pay a membership fee?",
    answer:
      "The membership fee enables us to maintain and enhance our platform, ensuring the availability of quality financial services and support for our members. It also helps cover operational costs and allows us to continuously improve our offerings to meet the evolving needs of our community.",
  },
  {
    question: "What is Goal savings?",
    answer:
      "Goal savings is a feature offered by SafeHome that allows members to set specific financial goals and save towards achieving them. Whether it's saving for a down payment on a house, starting a business, or planning for retirement, goal savings helps you stay focused and disciplined in reaching your objectives.",
  },
  {
    question: "What is Investment Savings?",
    answer:
      "Investment savings refers to a strategy where individuals allocate a portion of their savings towards investment opportunities with the aim of generating returns and growing their wealth over time. SafeHome provides access to curated investment options designed to help members achieve their financial goals through prudent investment strategies.",
  },
  {
    question: "How do I Invest?",
    answer:
      "To invest with SafeHome, you can explore the investment opportunities available on our platform and choose the ones that align with your financial goals and risk tolerance. Our team of experts carefully curates these opportunities, providing you with valuable insights to make informed investment decisions. Once you've selected an investment, you can easily initiate the investment process through our user-friendly interface.",
  },
];
