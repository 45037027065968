import config from "../utils/config";

const NotFound = () => {
	return (
		<div className="pt-[80px]">
			{/* Not Found landing page  */}

			<div className="container flex flex-col gap-[40px] items-center justify-center h-screen">
				<h1 className="text-[40px] leading-[50.2px] sm:text-[50px] sm:leading-[60.2px] md:text-[60px] md:leading-[70.2px] xl:text-[80px] xl:leading-[90.2px] font-bold">
					404
				</h1>
				<h3 className="text-[30px] leading-[40.2px] sm:text-[40px] sm:leading-[50.2px] md:text-[50px] md:leading-[60.2px] xl:text-[60px] xl:leading-[70.2px] font-semibold">
					Page Not Found
				</h3>
				<p className="text-center text-[#707070] text-[16px] leading-[26.2px] sm:text-[20px] sm:leading-[30.2px] md:text-[24px] md:leading-[34.2px] xl:text-[30px] xl:leading-[40.2px]">
					The page you are looking for might have been removed, had its name
					changed or is temporarily unavailable.
				</p>
				<a
					href="/"
					className="bg-[var(--btn)] text-white w-[256px] py-3 rounded-[2rem] text-center"
				>
					Go back to Home
				</a>
			</div>
		</div>
	);
};

export default NotFound;
