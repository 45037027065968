import "./App.css";
import {
	Home,
	About,
	Services,
	Products,
	Membership,
	Contact,
	NotFound,
	Waitlist,
	Affliates,
	Referrals,
	AffiliateReferral,
	PrivacyPolicy,
	TermsOfUse,
	Disclaimer,
} from "./pages";
import { Routes, Route } from "react-router-dom";
import { WebPageLayout } from "./components/appWrapper";

function App() {
	return (
		<div className="App">
			<div>
				<Routes>
					{/* Auth related Routes */}

					{/* Web page related Routes */}
					<Route element={<WebPageLayout />}>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/about" element={<About />} />
						<Route exact path="/services" element={<Services />} />
						<Route exact path="/products" element={<Products />} />
						<Route exact path="/membership" element={<Membership />} />
						<Route exact path="/r/:referralCode" element={<Referrals />} />
						<Route
							exact
							path="/a/:affiliateCode"
							element={<AffiliateReferral />}
						/>
						<Route exact path="/contact" element={<Contact />} />
						<Route exact path="/waitlist" element={<Waitlist />} />
						<Route exact path="/affiliates" element={<Affliates />} />

						<Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route exact path="/terms-of-use" element={<TermsOfUse />} />
						<Route exact path="/disclaimer" element={<Disclaimer />} />
						<Route exact path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</div>
		</div>
	);
}

export default App;
