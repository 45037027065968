import { AboutLikeSection, Card, AffiliateCTA } from "../components";
import affliates1 from "../assets/affliates-1.png";
import peopleIcon from "../assets/svgs/peopleIcon.svg";
import affiliateIcon from "../assets/svgs/connect.svg";
import promoteIcon from "../assets/svgs/promote.svg";
import blendicon from "../assets/svgs/blend.svg";
import buildingicon from "../assets/svgs/building.svg";
import buildingicon2 from "../assets/svgs/buildings-2.svg";
import shopicon from "../assets/svgs/shop.svg";
import FAQ from "../components/FAQ";

const faq = [
  {
    question: "How do I get paid?",
    answer:
      "You can get paid by providing your payment information in your account settings. Once you've earned commissions, we will process your payments through your chosen payment method.",
  },
  {
    question: "When do I receive my commissions?",
    answer:
      "Commissions are typically processed and paid out on a specific schedule, which may vary depending on the platform or program you are part of. Please check the terms and conditions or payment schedule for more details.",
  },
  {
    question: "Is there a cost to join?",
    answer:
      "There is no cost to join our program. It's completely free to become a member and start earning commissions. However, there may be certain requirements or qualifications to meet in order to participate, so please review the program's guidelines for specific information.",
  },
];

const Affliates = () => {
  return (
    <section className="pt-20">
      <div className="">
        {" "}
        <AboutLikeSection
          bg={true}
          img={affliates1}
          bgColor="bg-[--light-bg]"
          header="About Us"
          text="At Safe-home, we are more than just a financial platform; we are a community-driven  Multipurpose Cooperative Society. Our vision is to empower young professionals and entrepreneurs by providing access to well curated wealth creation opportunities."
          btnText="Learn More"
          btnUrl="/about"
          imgSide="right"
        />
        <div className="flex flex-col gap-[15px] max-w-[604px] mx-auto text-center pt-16 ">
          <h1 className="tect-[--header]">How it Works</h1>
        </div>
        <div className="grid grid-cols-1   md:grid-cols-3 gap-[1.3rem] md:gap-[2rem] pt-[35px] pb-[55px] md:pt-[50px] container">
          <Card
            icon={peopleIcon}
            title="Become a Member"
            text="Register on the web or download our app from the app store."
          />
          <Card
            icon={affiliateIcon}
            title="Become a Partner"
            text="Register for  our partner program and get your uniques referral link."
          />
          <Card
            icon={promoteIcon}
            title="Promote and Earn"
            text="Share your referral link and earn 40% of every membership fees from your referrals."
          />
        </div>
        <AffiliateCTA />
        <div className="flex flex-col gap-[15px] max-w-[604px] mx-auto text-center pt-16 ">
          <h1 className="text-[--header]">Program Benefits</h1>
        </div>
        <div className="grid grid-cols-1   md:grid-cols-2 lg:grid-cols-4 gap-[1.3rem] md:gap-[2rem] pt-[35px] pb-[55px] md:pt-[50px] container">
          <Card
            icon={blendicon}
            title="Generous Commissions"
            text="Earn competitive commissions for each membership you drive."
          />
          <Card
            icon={shopicon}
            title="Marketing Support"
            text="Access a library of marketing materials, including banners, text links, and content suggestions"
          />
          <Card
            icon={buildingicon}
            title="Real-Time Tracking"
            text="Monitor your performance and earnings in real-time through our user-friendly affiliate dashboard.
            "
          />

          <Card
            icon={buildingicon2}
            title="Dedicated Support"
            text="Our partners support team is here to assist you with any questions or concerns.
            "
          />
        </div>
        <div className="container py-[35px] md:py-[80px]">
          <h3 className="multi-color-text text-center md:text-[2rem] md:leading-[3.3rem] xl:text-[2.5rem] xl:leading-[3.8rem] max-w-[850px] mx-auto">
            At SafeHome <span>Cooperative</span>, we prioritize{" "}
            <span>your</span> financial growth <span>and</span> security.{" "}
            <span>Our</span> savings <span>and</span> investment{" "}
            <span>plans are</span> tailored <span>to</span> empower{" "}
            <span>you in</span> achieving <span>your</span> goals while{" "}
            <span>promoting</span> financial discipline. Join{" "}
            <span>us today</span> and embark <span>on a</span> journey{" "}
            <span>of</span> prosperity <span>and</span> success.
          </h3>
        </div>
        {/* FAQ  */}
        <FAQ faq={faq} />
        {/* FAQ ends  */}
      </div>
    </section>
  );
};

export default Affliates;
