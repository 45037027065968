import React from "react";
import { Navlink } from "./Navbar";
import config from "../utils/config";
import { Slide } from "react-awesome-reveal";

function Sidebar() {
	return (
		<>
			<div className="fixed lg:hidden top-0 right-0 h-screen w-full bg-[rgba(0,0,0,0.5)] z-[1]"></div>
			<Slide
				className="fixed lg:hidden top-0 right-0 min-h-screen inset-y-0 w-full bg-[rgba(0,0,0,0.5) z-[10]"
				direction="left"
				duration={300}
				delay={200}
			>
				<div className="h-full">
					<div className="flex w-[235px] h-full flex-col items-start justify-between bg-white pt-[100px] pb-[2rem] px-[1.5rem] shadow">
						<div className="flex flex-col items-start gap-4 w-full">
							<Navlink nav="About  Us" link="/about" />
							<Navlink nav="Services" link="/services" />
							<Navlink nav="Products" link="/products" />
							<Navlink nav="Membership" link="/membership" />
							<Navlink nav="Contact" link="/contact" />
						</div>
						<div className="py-5 flex lg:hidden flex-col gap-3 items-center w-full">
							<a
								href={config.urlMaps.signIn}
								target="_blank"
								rel="noopener noreferrer"
								className="w-full text-[var(--btn)] border border-[var(--btn)] p-1 py-[6px] rounded-[2rem] text-center shadow"
							>
								Log in
							</a>
							<a
								href={config.urlMaps.signUp}
								target="_blank"
								rel="noopener noreferrer"
								className="w-full text-white bg-[var(--btn)] border border-[var(--btn)] p-1 py-[6px] rounded-[2rem] text-center shadow"
							>
								Sign Up
							</a>
						</div>
					</div>
				</div>
			</Slide>
		</>
	);
}

export default Sidebar;
