import React from "react";
import { HashLink } from "react-router-hash-link";

import { DownloadCTA } from "../components";

import facebook from "../assets/svgs/facebook.svg";
import twitter from "../assets/svgs/twitter.svg";
import linkedin from "../assets/svgs/linkedin.svg";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";

import config from "../utils/config";

import logo from "../assets/svgs/logo-light.svg";

const Footer = () => {
  return (
    <div>
      <DownloadCTA />
      <div>
        <div className="bg-[var(--footer)] py-[50px] text-white">
          <div className="container flex flex-col lg:flex-row justify-between gap-6 lg:gap-[2rem] xl:gap-[3.5rem]">
            <div className="max-w-[250px] md:max-w-[300px] lg:max-w-[200px]">
              <img
                src={logo}
                alt="logo"
                className="w-[150.9px] h-[66px] mb-7"
              />
              <p className="footer-link">
                We are on a mission to transform the way people save, invest,
                and grow their finance by making real estate investments
                uncomplicated and stress-free.
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-6 lg:gap-2">
              <div>
                <h5 className="footer-header">ABOUT</h5>
                <HashLink className="footer-link" to="/about#vision">
                  Vision
                </HashLink>
                <HashLink className="footer-link" to="/about#mission">
                  Mission
                </HashLink>
              </div>
              <div>
                <h5 className="footer-header">SERVICES</h5>
                <a className="footer-link" href="/services">
                  Investment
                </a>
                <HashLink className="footer-link" to="/products#savings">
                  Savings
                </HashLink>
                <HashLink className="footer-link" to="/products#loans">
                  Loans
                </HashLink>
              </div>
              <div>
                <h5 className="footer-header">MEMBERSHIP</h5>
                <a className="footer-link" href={config.urlMaps.signIn}>
                  Member
                </a>
                <a className="footer-link" href={config.urlMaps.signIn}>
                  Investor
                </a>
                <a
                  className="footer-link"
                  href="https://partners.safehomecoop.com"
                >
                  Partner
                </a>
              </div>
              <div>
                <h5 className="footer-header">LEGAL</h5>
                <a className="footer-link" href="/privacy-policy">
                  Privacy Policy
                </a>
                <a className="footer-link" href="/terms-of-use">
                  Terms of Use
                </a>
                {/* <a className="footer-link" href="/disclaimer">
									Disclaimer
								</a> */}
              </div>
            </div>
            <div className="lg:text-right max-w-[200px]">
              <h5 className="footer-header">CONTACT</h5>
              <div className="flex lg:justify-end gap-3 pt-2 pb-6">
                <a
                  className="icon-link"
                  href="https://web.facebook.com/safehomecoop/"
                >
                  <img src={facebook} alt="facebook" />
                </a>
                <a
                  className="icon-link bg-gradient-to-tr from-[#ff9100] to-[#bc411f] rounded-full flex justify-center items-center"
                  href="https://www.instagram.com/safehomecoop/"
                >
                  <FaInstagram />
                </a>
                <a
                  className="icon-link"
                  href="https://twitter.com/safehomecoop"
                >
                  <img src={twitter} alt="twitter" />
                </a>
                <a
                  className="icon-link"
                  href="https://www.linkedin.com/in/safe-home/"
                >
                  <img src={linkedin} alt="linked in" />
                </a>
                <a
                  className="icon-link bg-gradient-to-tr from-[#ff9100] to-[#bc411f] rounded-full flex justify-center items-center"
                  href="https://wa.me/2348129940741"
                >
                  <FaWhatsapp />
                </a>
              </div>
              <a className="footer-link" href="tel:+2348129940741">
                +234 812 994 0741
              </a>
              <a className="footer-link" href="tel:+2348129940742">
                +234 812 994 0742
              </a>

              <a className="footer-link" href="mailto:support@safehomecoop.com">
                support@safehomecoop.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
