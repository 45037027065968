import React from "react";
import { Field, ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

function ContactUsForm() {
  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        message: "",
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string()
          .min(3, "Must be 3 characters or more")
          .max(32, "Must be 32 characters or less")
          .required("Required"),

        email: Yup.string().email("Invalid email address").required("Required"),

        message: Yup.string()
          .min(20, "Must be 20 characters or more")
          .max(512, "Must be 512 characters or less")
          .required("Required"),
      })}
    >
      {({ isValid }) => {
        return (
          <Form className="order-1 md:order-0 py-8 self-stretch md:pr-16 space-y-6 w-full  ">
            <legend className=" font-bold text-xl md:text-2xl lg:text-2xl text-[#1E0700]">
              Send us a message
            </legend>

            <div className="space-y-8 w-full ">
              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="email"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Full Name
                </label>

                <Field
                  name="fullName"
                  type="text"
                  className="field-1"
                  placeholder="Enter your full name"
                />

                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>

              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="email"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Email Address
                </label>

                <Field
                  name="email"
                  type="email"
                  className="field-1"
                  placeholder="Enter your email address"
                />

                <ErrorMessage
                  name="email"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>

              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="message"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Message
                </label>

                <Field
                  name="message"
                  as="textarea"
                  type="text"
                  className="field-1 resize-none h-[100px]"
                  placeholder="Write your message"
                />

                <ErrorMessage
                  name="message"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>
            </div>

            <div className="flex flex-col justify-center items-center w-full pt-16 space-y-4 ">
              <button
                disabled={!isValid}
                type="submit"
                className="btn-1 font-medium"
              >
                Send Message
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactUsForm;
