import React from "react";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <section className="pt-[80px]">
      <div
        id="loans"
        className="bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]"
      >
        <div className="container">
          <div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
            <h1 className="text-[--heade]">Terms of Use</h1>
          </div>
        </div>
      </div>

      {/* ----------------------------- */}

      <div className="py-10 space-y-8">
        <div className="bg-[--card-b]">
          <div className="container space-y-3">
            <h2 className="privacyHeaders">
              {" "}
              Introduction and Acceptance of Terms
            </h2>

            <p>
              Welcome to SafeHome, Nigeria's innovative platform for savings,
              real estate investment, loans, and more. By accessing and using
              our services, you agree to comply with the Terms of Use outlined
              below. These terms govern your use of SafeHome and all associated
              services.
            </p>

            <h3>General Provisions</h3>

            <div className="space-y-4">
              <p>
                1. The Terms and Conditions listed below govern the access and
                use of our website (hereinafter called “the Website”) and mobile
                phone application (“the Application”) that enables you to save
                and invest mobile money (“the Service”)
              </p>

              <p>
                2. In these Terms of Use, the terms “We”, “Us”, “Our” refer to
                SafeHome, and “You”, “Your” refer to any person who accesses
                and/or uses the service. “SafeHome Platform” refers to either
                the app or website.
              </p>

              <p>
                3. “Business Day” means any day other than a Saturday, Sunday,
                or statutory public holiday in Nigeria.
              </p>

              <p>
                4. You accept these terms as modified, supplemented or updated
                during your registration, and they will be legally binding when
                you use the SafeHome Platform.
              </p>

              <p>
                5. It is important that you understand that access and use of
                the Services (as defined below) is conditional on your
                acceptance of these Terms of Use. By using or accessing the
                Services, you signify that you have read and understood these
                Terms and our Privacy Policy and you agree to be bound by them.
              </p>
            </div>

            <h2 className="privacyHeaders">
              User Eligibility and Account Registration
            </h2>

            <p>To use SafeHome's services,</p>

            <div className="space-y-4">
              <p>
                1. You must be at least 18 years old and legally eligible to
                enter into binding contracts.
              </p>

              <p>2. You must accept these Terms and our Privacy Policy.</p>

              <p>
                3. You must be a Nigerian citizen (or a legal Nigerian resident)
                with a Nigerian bank account.
              </p>

              <p>
                4. You must be registered on the SafeHome platform. Upon
                registering, you'll be required to provide accurate personal
                information for identity verification in line with Nigeria's KYC
                regulations.
              </p>
            </div>

            <p>
              We reserve the right, at any time, to terminate or suspend your
              access to the Service without prior notice if:
            </p>

            <div className="space-y-4">
              <p>
                1. You use the Service or attempt to use it for any Prohibited
                Purpose;
              </p>

              <p>
                2. You attempt to transfer or charge funds from an account that
                does not belong to you;
              </p>

              <p>
                3. We receive conflicting claims regarding ownership of or the
                right to withdraw funds from the account;
              </p>

              <p>
                4. You have provided us with false evidence of your identity or
                you keep failing in providing us with true, accurate, current
                and complete evidence of your identity or details regarding
                transactions;
              </p>

              <p>
                5. You attempt to tamper, hack, modify, overload, or otherwise
                corrupt or circumvent the security and/or functionality of the
                Application or to infect it with any Malicious Code;
              </p>

              <p>
                6. You are in breach of our Terms of Use, or we have reason to
                believe that any of the foregoing has occurred or is likely to
                occur.
              </p>
            </div>

            <h2 className="privacyHeaders">Description of Services</h2>

            <p>
              SafeHome offers a range of financial services, including
              goal-based savings, inestment savings, collective real estate
              investment opportunities, access to loans, and our Buy Now Pay
              Later feature. Our platform aims to provide flexible, secure, and
              profitable financial solutions. Please note, investment
              opportunities involve risks, which are detailed in our Risk
              Disclosure section.
            </p>

            {/* BG COLOR YELLOW  */}

            <div className="bg-yellow-400 space-y-4">
              <h2 className="privacyHeaders">WALLET</h2>

              <p>
                There is a payment wallet called “Wallet” on the SafeHome
                Platform. It is the primary fund management wallet for
                Transfers, goal-based savings, and investment savings. It is
                used in making all transactions on the SafeHome platform,
                including funding your saving goals and investments. It is also
                the wallet that receives all payments from the dividend of any
                investment or savings you make.
              </p>

              <h3> Funding your Wallet </h3>

              <p>
                You can fund your wallet primarily through your debit card or
                other third-party bank accounts assigned to you. To fund your
                Wallet simply click on the “Add Money” tab and select the source
                of your funds, whether through card payment or bank transfer. We
                debit your card according to the amount you specify. You can
                request for your savings at any time, depending on the specific
                duration you set.
              </p>

              <h3> Transferring funds from your Wallet</h3>

              <p>
                Your funds will remain in your SafeHome Wallet until you
                instruct us to transfer any or all of it for savings,
                investments, or to your registered bank account. You can only
                make transfers to bank accounts linked to your BVN. In transfers
                to bank accounts, you will receive the requested funds within
                two working days of us receiving your request. If you do not
                have sufficient available funds in your Wallet to cover the
                amount of the requested transfer, your request for the transfer
                will be declined.
              </p>

              <p>Notes:</p>

              <div className="space-y-4">
                <p>
                  ● There is a maximum cap on withdrawals, and this is subject
                  to change from time to time.
                </p>

                <p>
                  ● Card payments may include bank charges based on the service
                  provider, while payments with bank transfer include a flat
                  processing fee of - naira.
                </p>

                <p>
                  ● Depositing or transferring into your wallet also comes at a
                  fee for amounts exceeding - naira.
                </p>
              </div>
            </div>

            {/* BG COLOR YELLOW  */}

            <h2 className="privacyHeaders">
              {" "}
              User Responsibilities and Conduct
            </h2>

            <p>
              As a SafeHome user, you agree to comply with all applicable laws
              and regulations and to use our platform for lawful purposes only.
              You're responsible for maintaining the confidentiality of your
              account information and for all activities under your account. Any
              unauthorized or illegal use of your account should be reported to
              SafeHome immediately. In relation to your registration and use of
              our Services, you also agree to:
            </p>

            <div className="space-y-4">
              <p>
                1. Provide us with the necessary documentation to verify your
                identity and eligibility, such as a government-issued photo ID,
                a Bank Verification Number (BVN), a utility bill, a bank
                statement, or any such other information that may be required
                from time to time.
              </p>

              <p>
                2. Ensure that all information provided to us is accurate,
                complete, error-free, and true.
              </p>

              <p>3. Not misrepresent your identity or user information.</p>

              <p>
                4. Not share or allow access to your account by anyone else.
                Your account with SafeHome is personal to you.
              </p>

              <p>
                5. Promptly notify us of changes to user information by updating
                your SafeHome profile on our platform(s).
              </p>

              <p>
                6. Authorize us to obtain information about you and your bank
                account directly or indirectly through our third-party service
                providers for seamless verification;
              </p>

              <p>
                7. Not use the service for or in connection with any prohibited
                purpose or attempt to tamper, hack, modify, overload, or
                otherwise corrupt or circumvent the security and/or
                functionality of our platform(s) or to infect it with any
                malicious code.
              </p>
            </div>
            <p>You understand and accept that:</p>

            <div className="space-y-4">
              <p>
                1. We are legally obliged to retain information about our users
                and the transactions that we process for a period of time as
                required by regulatory authorities;
              </p>

              <p>
                2. We will charge you for our service according to the rate that
                we publish on our app or that we inform you of before we confirm
                your withdrawal;
              </p>

              <p>
                3. We reserve the right to increase the charges as per market
                demands
              </p>

              <p>
                4. You will be liable to us for all losses which we suffer or
                incur in relation to any fraud or fraudulent activity by you;
              </p>

              <p>
                {" "}
                5. You must contact us by phone or email as soon as possible if
                you believe or suspect that a withdrawal was not done correctly
                or that you did not receive the full amount.
              </p>
            </div>

            <h2 className="privacyHeaders">Privacy Policy</h2>

            <p>
              Your privacy is paramount to us. SafeHome's Privacy Policy
              outlines how we collect, use, protect, and disclose your personal
              information. We are committed to safeguarding your data in
              accordance with Nigeria's data protection regulations. Read up on
              our Privacy Policy{" "}
              <Link to="/privacy-policy" className="text-yellow-500">
                here
              </Link>
              .
            </p>

            <h2 className="privacyHeaders"> Intellectual Property Rights</h2>

            <p>
              All content on the SafeHome platform, including text, graphics,
              logos, and software, is the property of SafeHome or its licensors.
              The unauthorized reproduction, modification, or distribution of
              any content on the platform is strictly prohibited.
            </p>

            <h2 className="privacyHeaders">
              {" "}
              Disclaimers and Limitations of Liability
            </h2>

            <div className="space-y-4">
              <p>
                SafeHome provides its services on an “as is” and “as available”
                basis, without any warranties of any kind, either express or
                implied, including but not limited to warranties of
                merchantability, fitness for a particular purpose,
                non-infringement, or accuracy.
              </p>

              <p>
                While we strive to ensure the accuracy and reliability of our
                services, SafeHome does not guarantee that its services will be
                uninterrupted, secure, error-free, or free from viruses or other
                harmful components. We disclaim all liability for any loss or
                damage arising from your use of SafeHome, to the extent
                permitted by law.
              </p>

              <p>
                SafeHome does not endorse, warrant, or assume responsibility for
                any third-party products, services, content, or websites that
                are linked to or from its platform. SafeHome is not liable for
                any loss or damage that may arise from your use of or reliance
                on any such third-party products, services, content, or
                websites.
              </p>

              <p>
                To the maximum extent permitted by applicable law, SafeHome and
                its affiliates, directors, officers, employees, agents, and
                partners shall not be liable for any direct, indirect,
                incidental, special, consequential, punitive, or exemplary
                damages, including but not limited to damages for loss of
                profits, goodwill, use, data, or other intangible losses,
                arising out of or in connection with your use of or inability to
                use SafeHome’s services, or any act or omission of any third
                party involved in providing SafeHome’s services, even if
                SafeHome has been advised of the possibility of such damages. In
                no event shall SafeHome’s total liability to you for all claims,
                damages, losses, and causes of action exceed the amount paid by
                you, if any, for accessing or using SafeHome’s services.
              </p>
            </div>
            <h3>Indemnity</h3>

            <p>
              You agree to indemnify, defend, and hold harmless SafeHome and its
              affiliates, directors, officers, employees, agents, and partners
              from and against any and all claims, liabilities, damages, losses,
              costs, expenses, and fees (including legal costs and attorneys
              fees) caused by a default of your (or anyone using your account's)
              violation of these terms of use or your misuse of or inability to
              use SafeHome's services.
            </p>

            <p>
              SafeHome reserves the right to assume the exclusive defense and
              control of any matter otherwise subject to indemnification by you,
              and, in such case, you agree to cooperate with SafeHome's defense
              of such claim. We will not be liable for:
            </p>

            <div className="space-y-4">
              <p>
                1. The actions or inactions of any third party that is not
                following our instructions, or the actions or inactions that are
                not directly caused by us.
              </p>

              <p>
                2. Any delayed payments due to network errors or issues with the
                Nigeria Inter-Bank Settlement System (NIBSS).
              </p>

              <p>
                3. Any failure to complete a payment instruction because of
                inaccurate or insufficient payment information, or because the
                recipient or bank did not claim the payment, or because you or
                the recipient did not provide any requested information, or
                because we or the issuing banks could not verify your or the
                recipient’s identity to our satisfaction.
              </p>
            </div>

            {/* BG YELLOW  */}

            <div className="bg-yellow-400">
              <p>
                SafeHome does not provide financial or investment advice, and
                our services are not intended to serve such a purpose. While we
                may show you indicative interest rates or returns for certain
                investments on our platform, you understand and accept that the
                value of any investment and the assets may go up or down due to
                market fluctuations, and that the value of the assets or
                interest rates (as applicable) in your SafeHome account may
                change accordingly. You acknowledge that we have not made any
                guarantee, either oral or in writing, that you will achieve your
                investment objective or that the value of the assets invested
                through your account will not decrease.
              </p>
            </div>

            {/* BG YELLOW  */}

            {/* BG RED SECTION  */}

            <div className="bg-red-400 space-y-2">
              <h3>Amendment to Terms</h3>

              <p>
                SafeHome reserves the right to modify these Terms of Use at any
                time. We will provide notice of such changes through our
                platform. Continued use of our services after any changes
                indicates your acceptance of the new terms.
              </p>

              <h3>Governing Law and Dispute Resolution</h3>

              <p>
                These Terms of Use are governed by the laws of Nigeria. Any
                disputes arising under these terms will be resolved through
                arbitration in Nigeria, in accordance with the Arbitration and
                Conciliation Act.
              </p>

              <h3>Termination and Suspension</h3>

              <p>
                SafeHome reserves the right to terminate or suspend your account
                for any breach of these terms or for any illegal or unauthorized
                use of the platform.
              </p>

              <h3>Miscellaneous Provisions</h3>

              <p>
                This agreement constitutes the entire agreement between you and
                SafeHome regarding the use of our services. If any part of these
                Terms is found to be unenforceable, the remaining portions will
                remain in full effect. Our failure to enforce any right or
                provision will not be considered a waiver of those rights.
              </p>
            </div>

            {/* BG RED SECTION  */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
