// import { Hero2 } from "../components";
import waitlist from "../assets/waitlist-1.jpg";
import { TrackRecord } from "../components";
import WaitlistForm from "../components/WaitlistForm";

const Waitlist = () => {
	return (
		//  Contact us page  with contact us form

		<section>
			<div className="pt-[80px]">
				<div className="space-y-12 py-5 bg-[--light-bg]">
					<TrackRecord
						img={waitlist}
						header={
							<span className="lowercase">
								{" "}
								<span className="capitalize">Join</span> the{" "}
								<span className="capitalize">SafeHome</span> waitlist{" "}
							</span>
						}
						text="Welcome to SafeHome, where we dismantle the barriers to savings and investments in Nigeria.
			We meet you wherever you are in your financial journey, and together, we evolve. In a matter of
			minutes, we can have you investing in real estate like a pro. Are you ready to join our vibrant
			community of investors? Book a spot on our waitlist today to become a trailblazer on this exciting
			road to financial dividend."
						imgSide="right"
						bg="yes"
					/>
				</div>
			</div>

			<div className={`w-full pt-[0px] pb-[60px] `}>
				<div className="container">
					<div className="grid  grid-cols-1 md:grid-cols-1 gap-[0px] justify-center items-center max-w-[748px] mx-auto rounded-r-[8px]">
						<WaitlistForm />

						{/* right card ends  */}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Waitlist;
