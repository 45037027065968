const mainAppUrl =
  process.env.REACT_APP_MAIN_APP_URL || "http://app.safehomecoop.com";

const AFFILIATE_APP_URL =
  process.env.REACT_APP_AFFILIATE_APP_URL || "http://localhost:3001";

const config = {
  mainAppUrl,

  urlMaps: {
    signIn: mainAppUrl + "/sign-in",
    signUp: mainAppUrl + "/sign-up",
    // signIn: "/waitlist",
    // signUp: "/waitlist",
    affiliate: AFFILIATE_APP_URL,
  },

  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:7000/api/v1",

  apiPaths: {
    addWaitlistApplicant: "/misc/waitlist",
    requestWaitlistConfirmationCode: "/misc/waitlist/confirm",
  },
};

export default config;
