import { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import config from "../utils/config";
import logo from "../assets/svgs/logo-dark.svg";

import { IoCloseSharp } from "react-icons/io5";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import Sidebar from "./Sidebar";

export const Navlink = ({ nav, link }) => {
	const location = useLocation();

	return (
		<div
			className={`${
				location.pathname === link
					? "text-[var(--highlight)] font-medium"
					: "text-[var(--primary)]"
			} hover:text-[var(--highlight)] font-[500] border-b lg:border-0 border-[var(--card-bg)] w-full lg:w-auto pb-3 px-1 lg:p-0`}
		>
			<a href={link}>{nav}</a>
		</div>
	);
};

const Navbar = () => {
	const [navIsOpen, setNavIsOpen] = useState(false);
	//   const navigate = useNavigate();

	return (
		<div className="h-[80px] w-full bg-white shadow">
			<div className="px-[1.3rem] md:px-[3rem] lg:px-[6rem] flex items-center justify-between h-full">
				<div className="z-[100] hover:scale-110 transition-all transform w-[110px] lg:w-[160px] xl:w-[140px]">
					<a className="" href="/">
						<img
							src={logo}
							alt="logo"
							className="w-[110px] lg:w-[140px] h-auto object-contain"
						/>
					</a>
				</div>
				<div className="hidden lg:flex w-full items-center justify-center gap-6 xl:gap-8">
					<Navlink nav="About Us" link="/about" />
					<Navlink nav="Services" link="/services" />
					<Navlink nav="Products" link="/products" />
					<Navlink nav="Membership" link="/membership" />
					<Navlink nav="Contact" link="/contact" />
				</div>
				<div>
					<div className="py-5 hidden lg:flex flex-col md:flex-row gap-3 items-center">
						{/* <a
							href={config.urlMaps.signIn}
							target="_blank"
							rel="noopener noreferrer"
							className="w-[109px]  bg-white text-[var(--secondary)] border border-[var(--secondary)] p-1 py-[6px] rounded-[2rem] text-center shadow btn"
						>
							Log in
						</a> */}
						<a
							href={config.urlMaps.signIn}
							target="_blank"
							rel="noopener noreferrer"
							className="w-[109px] bg-white text-[var(--btn)] border border-[var(--btn)] p-1 py-[6px] rounded-[2rem] text-center shadow btn"
						>
							Log in
						</a>
						<a
							href={config.urlMaps.signUp}
							target="_blank"
							rel="noopener noreferrer"
							className="w-[109px] text-white bg-[var(--btn)] border border-[var(--btn)] p-1 py-[6px] rounded-[2rem] text-center shadow btn"
						>
							Sign Up
						</a>
					</div>
				</div>
				<div className="z-[1000]">
					<button
						className="lg:hidden text-3xl pt-1 "
						onClick={() => setNavIsOpen((prev) => !prev)}
					>
						{navIsOpen ? (
							<IoCloseSharp className="text-white " />
						) : (
							<HiMiniBars3CenterLeft />
						)}
					</button>
				</div>
			</div>

			{/* Mobile screen navigation */}
			{navIsOpen && <Sidebar />}
		</div>
	);
};

export default Navbar;
