import config from "../utils/config";
import { Field, ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useState, startTransition } from "react";

function isDigit(str) {
  return /^\d+$/.test(str);
}

function Spinner() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-6 h-6 border-2 border-[#1E0700] border-r-white border-t-white rounded-full animate-spin"></div>
    </div>
  );
}

function WaitlistForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading1, setLoading1] = useState(false);
  const [error1, setError1] = useState(false);
  const [success1, setSuccess1] = useState(false);

  const [uid, setUid] = useState("");

  async function requestConfirmationCode(email) {
    setLoading1(true);
    try {
      const res = await fetch(
        `${config.apiUrl}${config.apiPaths.requestWaitlistConfirmationCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (res.ok) {
        setSuccess1(true);

        const data = await res.json();

        setUid(data.uid);
      } else {
        const data = await res.json();

        setError1(data.detail + "");

        console.log(data);
      }
    } catch (err) {
      setError1(err.message);
    }

    setLoading1(false);
  }

  async function handleSubmit(values, { resetForm }) {
    if (error) {
      startTransition(() => {
        setError(null);
      });
    }

    if (success) {
      startTransition(() => {
        setSuccess(null);
      });
    }

    setLoading(true);

    const body = {
      fullName: values.fullName,
      phone: values.phoneNumber
        ? parsePhoneNumber(values.phoneNumber, { regionCode: "NG" }).number.e164
        : null,
      email: values.email,
      code: values.code,
      uid,
    };

    try {
      const res = await fetch(
        `${config.apiUrl}${config.apiPaths.addWaitlistApplicant}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (res.ok) {
        setSuccess(true);
        resetForm();
      } else {
        const data = await res.json();

        setError(data.detail + "");
      }
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }

  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        phoneNumber: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        fullName: Yup.string()
          .min(3, "Must be 3 characters or more")
          .max(32, "Must be 32 characters or less")
          .required("Required"),

        email: Yup.string().email("Invalid email address").required("Required"),

        code: Yup.string()
          .min(6, "Must be 6 characters or more")
          .max(6, "Must be 6 characters or less")
          .required("Required")
          .test("code-is-valid", "Invalid code", function (value) {
            return isDigit(value);
          }),

        phoneNumber: Yup.string()
          .min(10, "Must be 10 characters or more")
          .test("phone-is-valid", "Invalid phone number", function (value) {
            const result = parsePhoneNumber(value, { regionCode: "NG" });
            return result.valid;
          }),
      })}
    >
      {({ isValid, errors, touched, values }) => {
        if (error) {
          setTimeout(() => {
            setError(null);
          }, 5000);
        }

        if (error1) {
          setTimeout(() => {
            setError1(null);
          }, 5000);
        }

        return (
          <Form className="order-1 md:order-0 py-8 self-stretch md:pr-16 space-y-6 w-full  ">
            <legend className=" font-bold text-xl md:text-2xl lg:text-2xl text-[--text]">
              Join the queue
            </legend>

            {/* Messages  */}

            {(error || success) && (
              <div className="font-medium text-left text-sm md:text-base ">
                {error && (
                  <div className="bg-[#cf0000] text-white rounded-md p-2">
                    {error}
                  </div>
                )}

                {success && (
                  <div className="bg-[#008f00] text-white rounded-md p-2">
                    Hurray 🥳 You are now on the queue, We will let you know
                    when we launch
                  </div>
                )}
              </div>
            )}

            <div className="space-y-8 w-full ">
              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="email"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Full Name
                  <span className="text-[#ff0000]">*</span>
                </label>

                <Field
                  name="fullName"
                  type="text"
                  className="field-1"
                  placeholder="Enter your full name"
                />

                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>

              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="email"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Email Address
                  <span className="text-[#ff0000]">*</span> {"  "} &nbsp;&nbsp;
                  {touched.email && !errors.email && (
                    <span
                      onClick={() => requestConfirmationCode(values.email)}
                      className="text-xs text-[#0000ff] hover:cursor-pointer hover:font-medium"
                    >
                      {loading1
                        ? "Sending..."
                        : success1
                        ? "Code sent"
                        : "Click to get Code"}
                    </span>
                  )}
                </label>

                <Field
                  name="email"
                  type="email"
                  className="field-1"
                  placeholder="Enter your email address"
                />

                <ErrorMessage
                  name="email"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>

              {touched.email && !errors.email && (
                <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                  <label
                    htmlFor="code"
                    className="text-[--text-secondary] text-sm text-left"
                  >
                    Confirmation Code <span className="text-[#ff0000]">*</span>
                    <span className="text-xs text-[#ff0000]">
                      {" "}
                      (Check your email)
                    </span>
                  </label>

                  <Field
                    name="code"
                    type="text"
                    inputMode="numeric"
                    className="field-1"
                    placeholder="Enter confirmation code"
                  />

                  <ErrorMessage
                    name="code"
                    component="div"
                    className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                  />
                </div>
              )}

              <div className="w-full relative flex flex-col justify-center items-start space-y-2">
                <label
                  htmlFor="phoneNumber"
                  className="text-[--text-secondary] text-sm text-left"
                >
                  Phone Number
                </label>

                <Field
                  name="phoneNumber"
                  type="text"
                  inputMode="numeric"
                  className="field-1"
                  placeholder="Enter your phone number"
                />

                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="absolute -bottom-[30%] left-0 text-[#ff0000] text-xs text-left"
                />
              </div>
            </div>

            <div className="flex flex-col justify-center items-center w-full space-y-4 pt-6">
              <button
                disabled={!isValid || loading}
                type="submit"
                className="btn-1 font-medium py-3"
              >
                {loading ? <Spinner /> : "Join"}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default WaitlistForm;
