import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import { useState } from "react";

function FAQ({ faq }) {
  const [activeIndex, setActiveIndex] = useState(null);

  function toggle(index) {
    return () => {
      if (activeIndex === index) {
        return setActiveIndex(null);
      }
      setActiveIndex(index);
    };
  }

  return (
    <div className="container py-[35px] md:py-[80px]">
      <h2 className="text-[#1E0700] text-3xl lg:text-5xl font-normal pb-8">
        {" "}
        Frequently Asked Questions{" "}
      </h2>

      <ul>
        {faq.map((item, i) => (
          <li
            onClick={toggle(i)}
            key={i}
            className="py-4 border-b border-[#1E1E1E] select-none"
          >
            <div className="flex justify-between items-center text-[#1E1E1E]  ">
              <h3 className=" text-base sm:text-lg md:text-xl ">
                {item.question}
              </h3>

              <div>
                {i !== activeIndex ? (
                  <BsChevronDown className="text-xl md:text-2xl" />
                ) : (
                  <BsChevronUp className="text-xl md:text-2xl" />
                )}
              </div>
            </div>

            <Fade duration={300} direction="up">
              {i === activeIndex ? (
                <p className={"text-base sm:text-lg md:text-xl  pt-6 "}>
                  {item.answer}
                </p>
              ) : (
                ""
              )}
            </Fade>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FAQ;
