import React from "react";

import {
	Hero2,
	VisionMissionCard,
	TrackRecord,
	JoinUsCTA,
} from "../components";

// IMPORTING ALL IMAGES START
import about from "../assets/about.png";
import about1 from "../assets/real-estate.png";
import about2 from "../assets/mastery-of-finance.png";
import about3 from "../assets/strong-portfolio.png";
import about4 from "../assets/strategic-leadership.png";
import FAQ from "../components/FAQ";
import { homepageFAQ } from "../utils/content";

const About = () => {
	return (
		<div className="pt-[80px]">
			<Hero2
				img={about}
				header1="About"
				header2="SafeHome"
				text={
					<div className="space-y-4">
						<p>
							SafeHome is more than just another run-of-the-mill financial
							platform. We are a people-driven multipurpose cooperative society.
							We are a community, not just an institution, and in there lies our
							unique strength.
						</p>

						<p>
							We are building a platform that empowers young professionals and
							entrepreneurs to scale their finances by providing access to
							well-curated savings and investment opportunities. We are pledged
							to bridge the gap between financial opportunities and
							accessibility.
						</p>

						<p>
							Welcome to a thriving hub of limitless possibilities. Hundreds of
							individuals have come to love the SafeHome way of investing. We
							promise you will, too.
						</p>

						<p className="font-bold">
							Think Savings! Think Investment! Think Growth! Think SafeHome!
						</p>
					</div>
				}
				imgSide="right"
			/>
			{/* Mission and Vision Statement */}
			<div className="pt-[35px] pb-[60px] md:py-[70px] bg-[var(--light-bg)]">
				<div className="container grid grid-cols-1 md:grid-cols-2 gap-[1.5rem] lg:gap-[2rem]">
					<div
						id="vision"
						className="mt-[-100px] pt-[100px] md:mt-[-120px] md:pt-[120px]"
					>
						<VisionMissionCard
							title="Vision Statement"
							text={
								<div className="space-y-4">
									<p>
										At SafeHome, we envision a world where financial security
										and prosperity are accessible to all. We are on a mission to
										transform the way people save, invest, and grow their
										finance by making real estate investments uncomplicated and
										stress-free.
									</p>

									<p>
										We aim to achieve this by building a vibrant community of
										diligent savers and forward-thinking investors. Our ambition
										is to be acknowledged as pioneers in delivering financial
										and real estateinvestment solutions throughout Africa.
										Ultimately, we aspire to shape a future where individuals
										across the continent turn to our platform to build a secure
										tomorrow.
									</p>
								</div>
							}
						/>
					</div>
					<div
						id="mission"
						className="mt-[-100px] pt-[100px] md:mt-[-120px] md:pt-[120px]"
					>
						<VisionMissionCard
							title="Mission Statement"
							text={
								<div className="space-y-4">
									<p>
										SafeHome seeks to deliver an innovative fin-tech real estate
										solution that empowers individuals to save wisely, invest
										smartly, access loans, and grow their portfolios in the
										simplest way possible. Our online platform is strategically
										designed to lower the traditional barriers that complicate
										savings and investing, particularly in real estate.
									</p>

									<p>
										Together, we achieve financial mastery by harnessing the
										power of community. Right here, we transform ordinary savers
										into extraordinary investors.
									</p>
								</div>
							}
						/>
					</div>
				</div>
			</div>
			{/* CTA */}
			<JoinUsCTA />
			{/* Track Record */}
			<div className="pt-[35px] pb-[35px] md:pt-[100px] md:pb-[50px] container">
				<div className="flex flex-col gap-[15px] max-w-[836px] mx-auto text-left md:text-center">
					<h1 className="text-[var(--header)] lg:scale-90">Our Track Record</h1>
					<p>
						At SafeHome Cooperative, our founding team is a unique blend of
						seasoned professionals from the real estate and finance sectors,
						collectively amassing an impressive half-century of industry
						experience. With a treasure trove of knowledge and a proven record
						of excellence, our experts have been at the forefront of the real
						estate and finance revolution across Africa, steering successful
						ventures and managing a robust portfolio of diverse assets. All our
						investments are meticulously curated by professionals with
						best-in-class experience in fin-tech, lending, leasing, and the
						global real estate market.
					</p>
				</div>
			</div>
			{/* Track record list */}
			<div className="">
				<TrackRecord
					img={about1}
					header="Real Estate Expertise"
					text="You wouldn’t want just anyone giving you advice on the hottest properties to buy in the real estate
		  market, right? Great, we don’t either. That is why our real estate specialists offer unparalleled
		  expertise in property analysis, market trends, and investment opportunities. With extensive global
		  industry experience, they have navigated highly successful real estate ventures, identified high-
		  potential properties, and constantly delivered outstanding returns to investors. You can kick back,
		  relax, and let them leverage their knowledge for you."
					imgSide="right"
					bg="yes"
				/>
				<TrackRecord
					img={about2}
					header="Financial Mastery"
					text="Our finance team possesses a profound understanding of financial markets, investment
		  strategies, and risk management. Their astute financial acumen has consistently enabled them
		  to optimize investment decisions and safeguard our members' interests."
					imgSide="left"
				/>
				<TrackRecord
					img={about3}
					header="Strong Portfolio Management"
					text="Backed by decades of collective experience, our founding team has accumulated and expertly
		  managed a diverse portfolio of real estate assets that have ensured the growth and stability of
		  our cooperative's investments. This impressive record of accomplishment of portfolio
		  management is a testament to their unwavering commitment to delivering financial success to our
		  valued members."
					imgSide="right"
					bg="yes"
				/>
				<TrackRecord
					img={about4}
					header="Strategic Leadership"
					text="A team of seasoned industry veterans who have the experience and strategic leadership qualities
		  to steer the cooperative towards sustained growth and prosperity lead SafeHome. Their
		  invaluable insights and foresight play a pivotal role in shaping the future of SafeHome Cooperative
		  and driving our collective success."
					imgSide="left"
				/>
			</div>

			<FAQ faq={homepageFAQ} />
		</div>
	);
};

export default About;
