import React from "react";

// import { Card, VisionMissionCard, TrackRecord, JoinUsCTA } from "../components";
import { TrackRecord, Card, JoinUsCTA } from "../components";

// IMPORTING ALL IMAGES START
import realEstate from "../assets/realEstate.png";
import switchIcon from "../assets/svgs/switchIcon.svg";
import documentsIcon from "../assets/svgs/switchIcon.svg";
import marketplaceIcon from "../assets/svgs/marketplaceIcon.svg";
import officeIcon from "../assets/svgs/officeIcon.svg";

import FAQ from "../components/FAQ";
import { homepageFAQ } from "../utils/content";

const Services = () => {
  return (
    <div className="pt-[80px]">
      <div className="bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]">
        <div className="container">
          <div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
            <h1 className="text-[--header]">Investments</h1>
            <p className="font-semibold">
              Explore our diverse and carefully vetted, high-yield real estate
              investment opportunities.
            </p>
          </div>
        </div>
      </div>

      <div className="pt-[25px] md:pt-[50px]">
        <TrackRecord
          img={realEstate}
          header="Real Estate Opportunities"
          text="With SafeHome, you can project your dividend returns while doing less work. Our
		  cooperative specializes in highly lucrative real estate investments with juicy returns. We
		  collaborated with industry experts who are trained to identify high-value properties and
		  secure a rewarding investment journey for our members. As we intend to broaden our
		  scope and introduce more exciting investment opportunities."
          imgSide="right"
        />
      </div>

      <div className=" py-[50px] md:py-[70px] bg-[var(--light-bg)]">
        <div className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[1.5rem] md:gap-[2rem]">
          <Card
            icon={switchIcon}
            title="Land Owners Club"
            text="Invest in land units across various locations for steady passive rental income."
          />
          <Card
            icon={marketplaceIcon}
            title="Shop Owners Club"
            text="Own units in bustling commercial spaces and earn from vibrant retail markets."
          />
          <Card
            icon={officeIcon}
            title="Office Owners Club"
            text="Join a community of investors in prime office spaces, generating consistent rental returns."
          />
          <Card
            icon={documentsIcon}
            title="Home Owners Club"
            text="Experience the pride and security of earning passively and owning units of residential properties across sought-after neighborhoods."
          />
        </div>
      </div>

      <div className="hidden bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]">
        <div className="container">
          <div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
            <h1 className="text-[--header]">Loans </h1>
            <p>
              With SafeHome, Get ready to unlock instant gratification and
              flexible payments with Our Buy Now, Pay Later Revolution.
            </p>
          </div>
        </div>
      </div>

      {/* CTA */}
      <JoinUsCTA />

      <div className="container py-[35px] md:py-[80px]">
        <h3 className="multi-color-text text-center md:text-[2rem] md:leading-[3.3rem] xl:text-[2.5rem] xl:leading-[3.8rem] max-w-[850px] mx-auto">
          At SafeHome <span>Cooperative</span>, we prioritize <span>your</span>{" "}
          financial growth <span>and</span> security. <span>Our</span> savings{" "}
          <span>and</span> investment <span>plans are</span> tailored{" "}
          <span>to</span> empower <span>you in</span> achieving{" "}
          <span>your</span> goals while <span>promoting</span> financial
          discipline. Join <span>us today</span> and embark <span>on a</span>{" "}
          journey <span>of</span> prosperity <span>and</span> success.
        </h3>
      </div>

      <FAQ faq={homepageFAQ} />
    </div>
  );
};

export default Services;
