import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="pt-[80px]">
      <div
        id="loans"
        className="bg-[var(--light-highlight2)] pt-[70px] pb-[35px] md:pt-[100px] md:pb-[50px]"
      >
        <div className="container">
          <div className="md:max-w-[500px] mx-auto md:text-center flex flex-col gap-[25px] md:items-center">
            <h1 className="text-[--heade]">Privacy Policy</h1>
          </div>
        </div>
      </div>

      <div className="py-10 space-y-8">
        <div className="bg-[--card-b]">
          <div className="container space-y-3">
            <p>
              SafeHome respects the privacy rights of every user and is
              committed to protecting your personal data. Please read this
              section carefully to understand our policies on the collection and
              usage of personal data through our app or website.
            </p>

            <h2 className="privacyHeaders"> Data Collection and Use</h2>

            <p className="leading-7">
              At SafeHome, we prioritize your privacy and are committed to
              safeguarding your personal information. This section outlines the
              types of data we collect and how we use it to enhance your
              experience on our platform.
            </p>

            <div className="space-y-4">
              <h3>Types of Data we collect:</h3>

              <p>
                ● Personal Identification Information: Name, email address,
                phone number, date of birth, National Identification Number
                (NIN). We collect this information from the data you submit
                during registration.
              </p>

              <p>
                {" "}
                ● Financial Data: We require your Bank Verification Number (BVN)
                and access to your transaction history.
              </p>

              <p>
                ● Usage Data: This data is collected through your app
                permissions and website visit patterns. We request app
                permissions to access your contact list, messages, and location.
                This information helps us give you a better user experience and
                savings recommendations.
              </p>

              <h3>Purpose of Data Collection</h3>

              <div className="space-y-2">
                <p>
                  SafeHome uses your data to manage your account, process
                  transactions, personalize our services to your preferences,
                  and ensure compliance with legal obligations.
                </p>

                <p>
                  We request permission to access your contact list information
                  to enable you to invite friends and family to join your
                  financial investment groups from the app. When you click
                  invite, a link is sent to te person to join your savings
                  group. It is solely by your authorization, and you can opt-out
                  of this feature.
                </p>

                <p>
                  The SafeHome app requests access to your messages to allow us
                  guide and advise you on how to save based on your
                  transactions. You can turn off this feature in the app.{" "}
                </p>
              </div>

              <h3> Processing your Personal Data</h3>

              <p>
                SafeHome processes user data and stores it in secure cloud
                servers. We also use third party data analysis tools to assist
                in data analytics of the users data. Every information requested
                is retained by us.
              </p>

              <h2 className="privacyHeaders"> Legal Compliance</h2>

              <p>
                SafeHome operates in strict adherence to the Nigerian Data
                Protection Regulation and other relevant financial service
                regulations. We take all necessary steps to ensure that your
                data is processed lawfully and ethically.
              </p>

              <h2 className="privacyHeaders"> User Consent</h2>

              <p>
                Your consent is paramount. We obtain your explicit consent for
                data collection at the point of registration and inform you
                about the specific use of your data. You may withdraw your
                consent at any time, which may restrict your access to our
                services.
              </p>

              <h2 className="privacyHeaders"> Data Sharing and Disclosure</h2>

              <p>
                SafeHome does not share your personal data with third parties
                except where you have given your consent. Your data is
                confidential and shared only under specific circumstances, such
                as compliance with legal requests, partnership agreements for
                service enhancement, or as part of regulatory obligations. For
                better specificity, we may share your personal data with third
                parties only if:
              </p>

              <div className="space-y-4">
                <p>
                  ● Sharing is necessary to enforce contract terms with you or
                  protect the rights and safety of the services provided by
                  SafeHome.
                </p>
                <p>
                  ● To comply with regulatory obligations or legitimate legal
                  injunctions from the government.
                </p>
                <p>
                  ● To cooperate with law enforcement agencies in cases of
                  illegal activities by a user. We reserve the right to report
                  to law enforcement agencies any activity we believe is
                  unlawful.
                </p>
                <p>
                  ● To regulated partners and third parties who provide services
                  to SafeHome. We ensure such parties only use the data as
                  required by law and do not disclose to anyone else or retain
                  copies.
                </p>

                <p>
                  ● You authorize relevant institutions to access your financial
                  information with us.
                </p>
              </div>

              <h2 className="privacyHeaders"> Data Security and Retention</h2>
              <div className="space-y-4">
                <p>
                  SafeHome prioritizes the security and confidentiality of the
                  personal data collected from our clients and investors. We
                  employ industry-standard security measures, including
                  symmetric bank-level encryption, firewalls, access controls,
                  and regular system updates, to protect your data from
                  unauthorized access and data breaches. PCI-DSS-compliant
                  payment processors safeguard your funds. These measures are
                  designed to protect the integrity and confidentiality of
                  personal data, and we continuously review and update our
                  security protocols to align with best practices in the
                  industry.
                </p>

                <p>
                  Access to your personal data is strictly on a need-to-know
                  basis for our employees, agents, and contractors. We collect
                  no more personal data than is required to reduce the total
                  risk of harm should data loss or a breach in security occur.
                </p>

                <p>
                  No method of data transmission or electronic storage is
                  completely foolproof. Despite our best efforts, we cannot
                  guarantee absolute security of personal data against all
                  threats. There is always a risk of data breaches due to the
                  inherent vulnerabilities of online communication and
                  information systems. You also play a role in protecting your
                  Personal Information. It is your responsibility to keep your
                  password and username secure.
                </p>

                <p>
                  Your personal information is retained for as long as the
                  purpose for which it was collected continues. The information
                  is then destroyed unless its retention is required to satisfy
                  legal, reporting, or accounting requirements.
                </p>
              </div>

              <h3>Notification in the event of breach</h3>

              <p>
                In the unlikely event of a breach in the security of Personal
                Data from our end, we will notify you if you’re potentially
                affected. We reserve the right to delay notification if asked to
                do so by law enforcement or other authorities, or if giving
                notice immediately will increase the risk of harm to other
                users. You agree to notify us immediately of any unauthorized
                use of your SafeHome account or any other breach of security.
              </p>

              <h2 className="privacyHeaders"> User Data Rights</h2>

              <p>
                You have the right to access, rectify, or request deletion of
                your personal data. Please contact us through the provided
                channels to exercise these rights."
              </p>

              <h2 className="privacyHeaders"> Policy Updates</h2>

              <p>
                SafeHome reserves the right to revise this Privacy Policy at any
                time. Any such revisions will be communicated via email and
                updated on our website and app. The date of the last update will
                be clearly indicated, and modifications become effective
                immediately upon being posted. Access and use of our services is
                subject to acceptance of the Privacy Policy version in force at
                the time of use.
              </p>

              <h2 className="privacyHeaders"> Contact Information</h2>

              <p>
                For any questions or concerns regarding our data protection and
                privacy statement, please contact us at{" "}
                <a
                  href="mailto:support@safehomecoop.com"
                  className="text-yellow-500"
                >
                  support@safehomecoop.com
                </a>
                . Our dedicated support team will assist you promptly. Please
                note that we keep records of every correspondence with our
                clients.
              </p>

              <h2 className="privacyHeaders"> Cookies</h2>

              <p>
                At SafeHome, our website uses cookies to enhance and personalize
                your user experience. Cookies are small text files stored on
                your device every time you access a website. We use cookies to,
                among other things, gather information about the usage of our
                services and improve site functionality.
              </p>

              <div className="space-y-4">
                <h3> Types of Cookies used</h3>
                <p>
                  ● Session Cookies: These are temporary cookies used to
                  remember your online activities while visiting our website.
                  They are deleted once you close your browser.
                </p>
                <p>
                  ● Persistent Cookies: These remain on your device for a set
                  period until they’re manually deleted. They are used to
                  remember your preferences and actions across multiple visits.
                  They do not contain any information that can identify you
                  personally.
                </p>
              </div>

              <div className="space-y-4">
                <h3> We use cookies to:</h3>
                <p>
                  ● Analyze site traffic and user interaction to improve our
                  services.
                </p>
                <p>
                  ● Identify you after you have logged in and keep record of
                  your activities.
                </p>

                <p>
                  ● Recognise your PC when you return to our web site so that we
                  can understand your preferences and tailor our offerings to
                  match your interests.
                </p>

                <p>● Enhance website functionality and user accessibility.</p>

                <p>
                  ● Allow you to carry information across pages of our site and
                  avoid having to re-enter that information.
                </p>
                <p>● Remember your preferences for future visits.</p>
              </div>

              <div className="space-y-4 pb-16">
                <h3> Managing Cookies</h3>
                <p>
                  Cookies do not enable us to gather personal information about
                  you. You receive a notification before a cookie is stored, and
                  you have the option to accept, decline, or customize your
                  cookie settings through your browser. Please note that
                  disabling cookies may affect the functionality of some parts
                  of our website. You can check out information on deleting or
                  controlling cookies at{" "}
                  <a
                    href="http://www.allaboutcookies.org"
                    className="text-yellow-500"
                  >
                    {" "}
                    http://www.allaboutcookies.org
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
