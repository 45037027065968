import React from "react";

import hero from "../assets/hero.jpg";

const Hero = () => {
	return (
		<div className="w-full h-screen">
			{/* Image */}
			<div>
				<img src={hero} alt="hero" className="w-full h-screen object-cover" />
			</div>
			{/* Content */}
			<div className="absolute top-0 left-0 w-full h-screen flex justify-center items-center bg-[#000000bb]">
				<div className="container text-center flex justify-center items-center flex-col gap-[15px] pt-[80px]">
					<h1 className="text-white max-w-[868px] header">
						<span className="text-transparent bg-gradient-to-r from-[--highlight] to-[#bc411f] bg-clip-text">
							Community
						</span>{" "}
						Meets{" "}
						<span className="text-transparent bg-gradient-to-r from-[--highlight] to-[#bc411f] bg-clip-text">
							Real Estate!
						</span>{" "}
					</h1>
					<p className="text-white max-w-[488px]">
						With our foundation of community, trust, and shared financial goals,
						we can transform your ordinary savings into extraordinary
						investments.
					</p>
					<div className="py-5 flex flex-col md:flex-row gap-3 hidden">
						<button className="w-[150px] h-[54px] md:w-[155px] md:h-[58px] bg-black p-[0.6rem] rounded-xl text-center shadow">
							<div className="text-white flex flex-col justify-center items-center ">
								<span className="text-xs">Download on</span>
								<span className="font-semibold">PlayStore</span>
							</div>
							{/* <img src={playstore} alt="" className="h-full inline" /> */}
						</button>
						<button className="w-[150px] h-[54px] md:w-[155px] md:h-[58px] bg-[--appstore-bg] p-[0.6rem] rounded-xl text-center shadow">
							<div className="text-black  flex flex-col justify-center items-center ">
								<span className="text-xs">Download on the </span>
								<span className="font-semibold">App Store</span>
							</div>
							{/* <img src={appstore} alt="" className="h-full inline" /> */}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
